import React from 'react';


function PhoneNumber(props) {
  return (
    
    <a href={`tel:${props.number}`}>{props.number}</a>
  );
}

export default PhoneNumber;