import "./load.css";

function Load() {
  return (
    <div className="loading">
      <svg
        width="150px"
        height="150px"
        version="1.1"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        fill="#f0bc1d"
      >
        <g id="sparkles">
          <path d="m55.301 8.3008h0.19922c0.5 0 0.89844-0.30078 1-0.80078l0.60156-3.3984c0.10156-0.5-0.30078-1.1016-0.80078-1.1992-0.5-0.10156-1.1016 0.30078-1.1992 0.80078l-0.60156 3.3984c-0.10156 0.59766 0.30078 1.0977 0.80078 1.1992z" />
          <path d="m78.5 10.5c-0.39844-0.39844-1-0.39844-1.3984 0l-0.30078 0.30078-0.30078-0.30078c-0.39844-0.39844-1-0.39844-1.3984 0-0.39844 0.39844-0.39844 1 0 1.3984l0.30078 0.30078-0.30078 0.30078c-0.39844 0.39844-0.39844 1 0 1.3984 0.19922 0.19922 0.5 0.30078 0.69922 0.30078s0.5-0.10156 0.69922-0.30078l0.30078-0.30078 0.30078 0.30078c0.19922 0.19922 0.5 0.30078 0.69922 0.30078s0.5-0.10156 0.69922-0.30078c0.39844-0.39844 0.39844-1 0-1.3984l-0.30078-0.30078 0.30078-0.30078c0.39844-0.39844 0.39844-1 0-1.3984z" />
          <path d="m76.602 26.699l-1.1992 1.1992c-0.39844 0.39844-0.39844 1 0 1.3984 0.19922 0.19922 0.5 0.30078 0.69922 0.30078 0.19922 0 0.5-0.10156 0.69922-0.30078l1.1992-1.1992c0.39844-0.39844 0.39844-1 0-1.3984-0.39844-0.39844-1-0.39844-1.3984 0z" />
          <path d="m89.5 22.5c0 1.6016-2.3984 1.6016-2.3984 0s2.3984-1.6016 2.3984 0" />
          <path d="m95.898 37.801l0.5-0.5c0.39844-0.39844 0.39844-1 0-1.3984-0.39844-0.39844-1-0.39844-1.3984 0l-0.5 0.5-0.5-0.50391c-0.39844-0.39844-1-0.39844-1.3984 0-0.39844 0.39844-0.39844 1 0 1.3984l0.5 0.5-0.5 0.5c-0.39844 0.39844-0.39844 1 0 1.3984 0.19922 0.19922 0.5 0.30078 0.69922 0.30078s0.5-0.10156 0.69922-0.30078l0.5-0.5 0.5 0.5c0.19922 0.19922 0.5 0.30078 0.69922 0.30078s0.5-0.10156 0.69922-0.30078c0.39844-0.39844 0.39844-1 0-1.3984z" />
          <path d="m82.082 40.375c1.3203 0.90234-0.03125 2.8867-1.3516 1.9844-1.3242-0.89844 0.027343-2.8828 1.3516-1.9844" />
          <path d="m74.957 49.684c0.80078 1.3828-1.2773 2.5859-2.0781 1.1992-0.80078-1.3867 1.2773-2.5859 2.0781-1.1992" />
          <path d="m86.199 56.898c0.5 0 0.89844-0.30078 1-0.80078 0.10156-0.5-0.30078-1.1016-0.80078-1.1992l-3.3984-0.60156c-0.5-0.10156-1.1016 0.30078-1.1992 0.80078-0.10156 0.5 0.30078 1.1016 0.80078 1.1992l3.3984 0.60156h0.19922z" />
          <path d="m92.465 65c1.5898 0.17578 1.3281 2.5586-0.26562 2.3828-1.5898-0.17578-1.3242-2.5586 0.26562-2.3828" />
          <path d="m77 69.398h-0.69922v-0.69922c0-0.60156-0.39844-1-1-1-0.60156 0-1 0.39844-1 1v0.69922h-0.69922c-0.60156 0-1 0.39844-1 1 0 0.60156 0.39844 1 1 1h0.69922v0.60156c0 0.60156 0.39844 1 1 1 0.60156 0 1-0.39844 1-1v-0.69922h0.69922c0.60156 0 1-0.39844 1-1 0-0.60156-0.5-0.90234-1-0.90234z" />
          <path d="m85.602 79.301c-1.3984 0-2.5 1.1016-2.5 2.5 0 1.3984 1.1016 2.5 2.5 2.5 1.3984 0 2.5-1.1016 2.5-2.5 0-1.3008-1.1016-2.5-2.5-2.5zm0 3c-0.30078 0-0.5-0.19922-0.5-0.5s0.19922-0.5 0.5-0.5c0.30078 0 0.5 0.19922 0.5 0.5s-0.20312 0.5-0.5 0.5z" />
          <path d="m73.352 86.312c1.5938 0.17578 1.3281 2.5586-0.26172 2.3828-1.5898-0.17578-1.3281-2.5586 0.26172-2.3828" />
          <path d="m59 88.699c-1.8008 0-3.3008 1.5-3.3008 3.3008s1.5 3.3008 3.3008 3.3008 3.3008-1.5 3.3008-3.3008-1.5-3.3008-3.3008-3.3008zm0 4.6992c-0.69922 0-1.3008-0.60156-1.3008-1.3008 0-0.69922 0.60156-1.3008 1.3008-1.3008s1.3008 0.60156 1.3008 1.3008c0 0.70312-0.60156 1.3008-1.3008 1.3008z" />
          <path d="m43.316 94.305c1.5938 0.17578 1.3281 2.5625-0.26172 2.3867-1.5898-0.17578-1.3281-2.5625 0.26172-2.3867" />
          <path d="m37.602 88.801c0 1.5977-2.4023 1.5977-2.4023 0 0-1.6016 2.4023-1.6016 2.4023 0" />
          <path d="m24.398 92.102c0 1.5977-2.3984 1.5977-2.3984 0 0-1.6016 2.3984-1.6016 2.3984 0" />
          <path d="m25.07 75.891c1.3242 0.89844-0.027343 2.8828-1.3477 1.9844-1.3242-0.90234 0.027344-2.8867 1.3477-1.9844" />
          <path d="m11.301 76.801c-0.39844-0.39844-1-0.39844-1.3984 0l-0.30078 0.30078-0.30078-0.30078c-0.39844-0.39844-1-0.39844-1.3984 0-0.39844 0.39844-0.39844 1 0 1.3984l0.30078 0.30078-0.30078 0.30078c-0.39844 0.39844-0.39844 1 0 1.3984 0.19922 0.19922 0.5 0.30078 0.69922 0.30078 0.19922 0 0.5-0.10156 0.69922-0.30078l0.30078-0.30078 0.30078 0.30078c0.19922 0.19922 0.5 0.30078 0.69922 0.30078 0.19922 0 0.5-0.10156 0.69922-0.30078 0.39844-0.39844 0.39844-1 0-1.3984l-0.30078-0.30078 0.30078-0.30078c0.39844-0.39844 0.39844-1 0-1.3984z" />
          <path d="m19.176 66.262c1.3203 0.90234-0.03125 2.8867-1.3516 1.9844-1.3242-0.90234 0.027343-2.8828 1.3516-1.9844" />
          <path d="m8.1758 65.156c1.3242 0.89844-0.027343 2.8828-1.3516 1.9844-1.3203-0.90234 0.03125-2.8867 1.3516-1.9844" />
          <path d="m21.898 54.898c0.60156 0 1-0.39844 1-1 0-0.60156-0.39844-1-1-1h-0.69922v-0.69922c0-0.60156-0.39844-1-1-1-0.60156 0-1 0.39844-1 1v0.69922h-0.69922c-0.60156 0-1 0.39844-1 1 0 0.60156 0.39844 1 1 1h0.69922v0.69922c0 0.60156 0.39844 1 1 1 0.60156 0 1-0.39844 1-1v-0.69922z" />
          <path d="m7.6016 48.699c-0.30078-0.5-0.89844-0.60156-1.3984-0.19922l-2.8008 2c-0.5 0.30078-0.60156 0.89844-0.19922 1.3984 0.19922 0.30078 0.5 0.39844 0.80078 0.39844 0.19922 0 0.39844-0.10156 0.60156-0.19922l2.8008-2c0.49219-0.29688 0.59375-0.99609 0.19531-1.3984z" />
          <path d="m12.801 31.801c-1.6016 0-3 1.3008-3 3s1.3008 3 3 3 3-1.3008 3-3-1.3008-3-3-3zm0 3.8984c-0.5 0-1-0.39844-1-1 0-0.60156 0.39844-1 1-1 0.60156 0 1 0.39844 1 1 0 0.60156-0.40234 1-1 1z" />
          <path d="m24.488 28.703c1.5938 0.17969 1.3281 2.5625-0.26172 2.3867-1.5898-0.17578-1.3281-2.5625 0.26172-2.3867" />
          <path d="m12.98 16.508c1.5938 0.17578 1.3281 2.5586-0.26172 2.3828s-1.3281-2.5625 0.26172-2.3828" />
          <path d="m28.102 18.199l-0.5-0.5 0.5-0.5c0.39844-0.39844 0.39844-1 0-1.3984-0.39844-0.39844-1-0.39844-1.3984 0l-0.5 0.5-0.5-0.5c-0.39844-0.39844-1-0.39844-1.3984 0-0.39844 0.39844-0.39844 1 0 1.3984l0.5 0.5-0.5 0.5c-0.39844 0.39844-0.39844 1 0 1.3984 0.19922 0.19922 0.5 0.30078 0.69922 0.30078 0.19922 0 0.5-0.10156 0.69922-0.30078l0.5-0.5 0.5 0.5c0.19922 0.19922 0.5 0.30078 0.69922 0.30078 0.19922 0 0.5-0.10156 0.69922-0.30078 0.29688-0.39844 0.29688-0.99609 0-1.3984z" />
          <path d="m37.699 5.6992c0 1.6016-2.3984 1.6016-2.3984 0 0-1.5977 2.3984-1.5977 2.3984 0" />
          <path d="m46.801 10.301c0 1.5977-2.4023 1.5977-2.4023 0 0-1.6016 2.4023-1.6016 2.4023 0" />
        </g>
        <g id="burrito">
          <svg
            version={1.0}
            xmlns="http://www.w3.org/2000/svg"
            width="25.000000pt"
            height="23.000000pt"
            viewBox="0 0 252.000000 238.000000"
            preserveAspectRatio="xMidYMid meet"
            x={35}
            y={33}
          >
            <g
              transform="translate(0.000000,238.000000) scale(0.100000,-0.100000)"
              fill="#f0bc1d"
              stroke="none"
            >
              <path
                d="M737 2346 c-15 -7 -35 -22 -43 -35 l-16 -23 -19 31 c-35 57 -103 32
-143 -54 l-27 -55 -94 0 -95 0 0 60 0 60 -79 0 c-135 0 -121 134 -121 -1151
l0 -1099 1090 0 1090 0 0 200 0 200 -40 0 -40 0 0 95 0 95 65 0 c55 0 65 3 65
18 0 9 18 238 40 507 22 270 40 491 40 493 0 1 -47 2 -105 2 l-105 0 0 70 c0
40 -4 70 -10 70 -6 0 -10 -30 -10 -70 l0 -70 -105 0 c-105 0 -105 0 -105 -25
0 -34 69 -901 76 -952 l5 -43 65 0 64 0 0 -95 0 -95 -50 0 -50 0 0 -100 0
-100 -890 0 -890 0 0 951 c0 863 1 951 16 945 9 -3 54 -6 100 -6 69 0 84 -3
84 -15 0 -30 31 -75 55 -81 16 -4 35 0 52 11 23 16 29 16 52 3 65 -38 115 -29
133 23 15 44 52 40 77 -10 11 -21 17 -42 13 -46 -4 -4 4 -5 17 -3 22 3 45 35
69 98 7 18 8 6 4 -44 -5 -67 -5 -68 18 -61 33 11 93 13 111 4 23 -11 59 12 81
51 16 30 23 35 56 35 35 0 38 -3 52 -43 15 -43 17 -44 65 -47 74 -6 110 10
145 60 17 24 30 48 30 53 0 18 30 22 168 24 120 1 128 2 67 8 -38 4 -109 8
-157 9 l-88 1 0 60 c0 71 -6 75 -83 66 -44 -6 -55 -11 -69 -37 -17 -29 -18
-29 -43 -13 -14 9 -43 16 -65 16 -28 -1 -40 3 -40 14 0 22 -34 17 -59 -10
l-22 -24 -26 24 c-39 36 -55 30 -87 -31 -16 -30 -32 -55 -37 -55 -5 0 -9 18
-9 39 0 30 -6 44 -22 55 -21 15 -24 14 -53 -12 -24 -22 -38 -27 -68 -24 -36 3
-38 5 -33 28 7 28 -9 64 -27 63 -7 0 -24 -6 -40 -13z"
              />
              <path
                d="M2066 2344 c-3 -8 -6 -43 -6 -75 l0 -60 43 3 c42 3 42 3 45 46 l3 42
55 0 54 0 0 -46 c0 -43 -7 -57 -105 -190 -58 -79 -105 -153 -105 -164 0 -19 6
-20 150 -20 l150 0 0 30 0 30 -90 0 c-49 0 -90 3 -90 6 0 3 35 54 78 113 104
140 103 139 100 224 l-3 72 -136 3 c-118 2 -137 0 -143 -14z"
              />
              <path
                d="M750 1920 c-72 -9 -133 -41 -159 -83 -19 -31 -21 -49 -21 -191 l0
-156 210 0 210 0 0 100 0 100 225 0 225 0 0 -159 0 -159 -367 -5 c-380 -5
-417 -9 -467 -48 -60 -47 -61 -56 -61 -386 0 -343 -2 -333 86 -383 65 -37 208
-38 521 -6 305 32 288 32 288 1 l0 -25 220 0 220 0 0 644 0 643 -23 34 c-29
43 -84 69 -167 80 -78 10 -862 9 -940 -1z m690 -960 l0 -190 -133 0 c-74 0
-175 -3 -225 -6 l-92 -7 0 197 0 196 225 0 225 0 0 -190z"
              />
            </g>
          </svg>
        </g>
      </svg>
    </div>
  );
}
export default Load;
