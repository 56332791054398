import React from "react";
import "./Contd.css";
import { Row, Col } from "react-bootstrap";
import PhoneNumber from './PhoneNumber';

const Contd = () => {
  return (
    <div className="cont-bg">
      <Row className="cont">
        <Col md={6} className="col-full-width">
          <div id="con-card">
            <div className="directors">
              {/* <h2 className="h2-title page-title">Contact Us</h2> */}
              <div className="address">
                <h2 className="h2-title">ADDRESS</h2>

                <p className="contact-content">
                  <span className="addd">Eldams Road Branch: </span> New no 21, Old no 14, 
                   Chamiers 3rd Ln, Nandanam, Chennai, Tamil Nadu 600035,  <PhoneNumber number="044-42627803" />,  <PhoneNumber number="8925821400" />
                  <br />
                  <br />
                  {/* <h2 className="h2-title">ADDRESS2</h2> */}
                  <span className="addd"> Old Washermanpet: </span> No 722, 558,
                  Thiruvottiyur High Rd, Korukkupet, Old Washermanpet, Chennai,
                  Tamil Nadu 600021, <PhoneNumber number="8925821404" />
                  <br />
                  <br />
                  <span className="addd">Pallavaram: </span>No 135, Mosque St,
                  near Inayath Mahal, Pallavaram, Chennai, Tamil Nadu 600043, <PhoneNumber number="8925821403" /> 
                  <br />
                  <br />
                  <span className="addd">Palavakkam: </span> 1/178, East coast
                  road,Palavakkam, East Coast Rd, Before, Anna Salai, signal,
                  Chennai, Tamil Nadu 600041, <PhoneNumber number="8925821402" />
                  {/* <div>
                        <h2>Phone Number:</h2>
                        <PhoneNumber number="8925821402" />
                  </div> */}
                  <br />
                  <br />
                  <span className="addd">Velachery: </span>No 1, Velachery Rd,
                  Vijaya Nagar, Velachery, Chennai, Tamil Nadu 600042, <PhoneNumber number="8925821405" />
                  <br />
                  <br />
                  <span className="addd">Madipakkam: </span>81, Medavakkam Main
                  Rd, opposite to Axis bank, Madipakkam, Chennai, Tamil Nadu
                  600091, <PhoneNumber number="8925821407" />
                  <br />
                  <br />
                  <span className="addd">George Town: </span>10,11 Broadway
                  road,Asirvada puram,George town, opp. to Muthialpet, Police
                  station, Chennai, Tamil Nadu 600108,  <PhoneNumber number="8925821406" />
                  <br />
                  <br />
                  <span className="addd">Vyasarpadi: </span>81, Medavakkam Main
                  No 270/208 Pudhu Nagar, MKB Nagar, Vyasarpadi, Chennai, Tamil
                  Nadu 600039, <PhoneNumber number="8925821408" />
                  <br />
                  <br />
                  <span className="addd">Choolaimedu: </span>
                  3/1 Sowrashtra nagar,First Street, Choolaimedu High Rd,
                  Chennai, Tamil Nadu 600094, <PhoneNumber number="8925821409" />
                  <br />
                  <br />
                  <span className="addd">Kotturpuram: </span>
                  19, Gandhi Mandapam Rd, Surya Nagar, Kotturpuram, Chennai,
                  Tamil Nadu 600085, <PhoneNumber number="8925821410" />
                  <br />
                  <br />
                  <span className="addd">Tiruvottiyur: </span>
                  967, Tiruvottiyur High Rd, Kaladipet, Tiruvottiyur,
                  Chennai, Tamil Nadu 600019 
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} className="col-full-width">
          <div className="map">
            <iframe
              src="https://www.google.com/maps/d/embed?mid=1uDIlj9nXwgXO0pFymZZ1txtkMiW0qvU&ehbc=2E312F"
              width="640"
              height="480"
              title="google-map"
            ></iframe>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Contd;
